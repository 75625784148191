import { ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { SwarmSchedule } from "../s25-swarm-schedule/SwarmSchedule";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25MeetingPatternGridListComponent } from "./s25.meeting.pattern.grid.list.component";
import { TelemetryService } from "../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-meeting-pattern-grid-page")
@Component({
    selector: "s25-ng-meeting-pattern-grid-page",
    template: `
        <h1>Optimizer Grids</h1>
        @if (!currentBoard) {
            <s25-ng-meeting-pattern-grid-list (viewBoard)="onViewBoard($event)"></s25-ng-meeting-pattern-grid-list>
        }
        @if (currentBoard) {
            <s25-ng-meeting-pattern-grid
                [board]="currentBoard"
                (exit)="onBoardExit($event)"
            ></s25-ng-meeting-pattern-grid>
        }
    `,
    styles: ``,
})
export class S25MeetingPatternGridPageComponent {
    @ViewChild(S25MeetingPatternGridListComponent) listComponent: S25MeetingPatternGridListComponent;

    currentBoard: SwarmSchedule.BoardI;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        elementRef.nativeElement.angBridge = this;
    }

    onViewBoard(board: SwarmSchedule.BoardI) {
        if (board === this.currentBoard) return;
        TelemetryService.sendWithSub("MPG", "Optimizer", "View");
        this.currentBoard = board;
        this.changeDetector.detectChanges();
    }

    onBoardExit(refresh: boolean) {
        this.currentBoard = null; // Unloads the grid component
        this.changeDetector.detectChanges();
        if (refresh) return this.listComponent?.forceRefresh(); // Refreshes the table
    }
}
